import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';

import SocialShare from '../components/SocialShare';
import Seo from '../components/Seo';

const NavLink = (props) => {
  const { test, url, text } = props;
  if (!test) {
    return <Link to={url}>{text}</Link>;
  }
  return <span>{text}</span>;
};

const News = ({ pageContext }) => {
  const title = 'News & Updates';
  const slug = '/news';
  const description = 'JLP news and updates.';

  const { group, index, pageCount } = pageContext;
  const first = index === 1;
  const last = index === pageCount;
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  return (
    <div>
      <h1
        className="page-header"
        data-aos="fade-in"
        data-aos-delay="150"
      >
        {title}
      </h1>
      <div className="page-content no-bg no-padding">
        {group.map(({ node }) => (
          <article
            className="article"
            key={node.prismicId}
            data-aos="fade-up"
            data-aos-offset="50"
          >
            <header className="clearfix">
              <time className="bumper alert">{node.data.date}</time>
              <h1 className="title">
                <Link
                  to={`/content/news/${node.prismicId}/${node.slugs[0]}`}
                >
                  {node.data.title}
                </Link>
              </h1>
            </header>
            <figure className="content">
              {node.data.image.fluid
                && (
                  <Image
                    fluid={node.data.image.fluid}
                  />
                )
              }
            </figure>
            <span
              className="hide-for-medium-up content"
              dangerouslySetInnerHTML={{ __html: node.data.teaser.html }}
            />
            <span
              className="show-for-medium-up content"
              dangerouslySetInnerHTML={{ __html: node.data.content.html }}
            />
            <SocialShare
              title={node.data.title}
              url={`/content/news/${node.prismicId}/${node.slugs[0]}`}
            />
          </article>
        ))}
        <div className="pagination-wrapper">
          <ul className="pagination">
            <li className="previousLink">
              <NavLink
                test={first}
                url={`/news/${previousUrl}`}
                text="Previous"
              />
            </li>
            <li className="nextLink">
              <NavLink
                test={last}
                url={`/news/${nextUrl}`}
                text="Next"
              />
            </li>
          </ul>
        </div>
        {group.length === 0 && (
          <span className="results-msg">
            0 articles found.
          </span>
        )}
        <Seo
          slug={slug}
          title={title}
          description={description}
        />
      </div>
    </div>
  );
};

NavLink.propTypes = {
  test: PropTypes.bool,
  url: PropTypes.string,
  text: PropTypes.string,
};

News.propTypes = {
  pageContext: PropTypes.object,
};

export default News;
