import React from 'react';
import { Link } from 'gatsby';
import { encode } from '../utils/helpers';

import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialShare = ({ title, url }) => {
  const titleEncoded = encode(title);
  const urlEncoded = 'https://ponty.com' + encode(url);
  return (
    <span className="social-share">
      <a
        className="twitter"
        title="Share on Twitter"
        href={`https://twitter.com/intent/tweet?text=${titleEncoded}+${urlEncoded}`}
        rel="me nofollow noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        className="facebook"
        title="Share on Facebook"
        href={`https://www.facebook.com/share.php?u=${urlEncoded}&title=${titleEncoded}`}
        rel="me nofollow noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
    </span>
  );
};

export default SocialShare;
